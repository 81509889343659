// $gutter: 0.5rem;
// $half-gutter: $gutter / 2;
// $quarter: $gutter + $half-gutter;
// $small: $gutter * 2;
// $medium: $gutter * 3;
// $large: $gutter * 4;
// $xxl: $gutter * 6;

:root {
  --spacing-gutter: 8px;
  --spacing-half-gutter: calc(var(--spacing-gutter) * 0.5);
  --spacing-quarter-gutter: calc(var(--spacing-gutter) * 1.5);
  --spacing-small-gutter: calc(var(--spacing-gutter) * 2);
  --spacing-medium-gutter: calc(var(--spacing-gutter) * 3);
  --spacing-large-gutter: calc(var(--spacing-gutter) * 4);
  --spacing-xxl-gutter: calc(var(--spacing-gutter) * 6);
}

html {
  font-size: 100%;
  height: 100%;
}

@layer base {
  h1 {
    font-size: 1.5rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: var(--content-title-color, var(--color-black));
    margin-top: 0;
    margin-bottom: 0;
  }
  
  h2,
  h3 {
    font-size: 1rem;
    font-weight: 500;
  }
}


::ng-deep :where(html:not(.dark-mode)) {
  .app-content block {
    a:visited:not(.unstyled-link) {
      color: var(--content-link-color, var(--color-link-visited)) !important;

      h1,
      h2,
      h3,
      h4 {
        color: var(--content-title-color, var(--color-link-visited)) !important;
      }
    }
  }
}

// Dark mode specific styles
::ng-deep .dark-mode {
  & .app-content block {
    a {

      &:hover,
      &:active {
        color: var(--color-dark-link);
        text-decoration-color: var(--color-dark-link);

        & h1,
        h2,
        h3,
        h4 {
          color: inherit;
        }
      }

      &:visited:not(.unstyled-link) {
        color: var(--color-dark-link) !important;

        & h1,
        h2,
        h3,
        h4 {
          color: var(--color-dark-link) !important;
        }
      }
    }
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.pointer {
  cursor: pointer;
}

.capitalize {
  text-transform: capitalize;
}
