@use 'breakpoints' as breakpoints;
$gridColumns: 12;
$gridGutter: 8px;
$xxs-break: 480px;
$xs-break: 720px;
$sm-break: 1024px;
$md-break: 1280px;
.row{display: flex; flex-wrap: wrap;margin:-$gridGutter;

	@for $i from 1 through $gridColumns { 
		$width: percentage( $i / $gridColumns );
		& > .col-#{$i} { width: $width; padding: var(--grid-gutter, #{$gridGutter}); }
		& > .col-offset-#{$i} { margin-left: $width; }

		@media screen and (max-width: $md-break) {
			& > .md-#{$i} { width: $width; padding: $gridGutter; }
		}

		@media screen and (max-width: $sm-break) {
			& > .sm-#{$i} { width: $width; padding: $gridGutter; }
		}

		@media screen and (max-width: $xs-break) {
			& > .xs-#{$i} { width: $width; padding: $gridGutter; }
		}

		@media screen and (max-width: $xxs-break) {
			& > .xxs-#{$i} { width: $width; padding: $gridGutter;}
		}
	}

	@media screen and (max-width:$md-break) {
		.md-hide { display: none; }
	}

	@media screen and (max-width:$sm-break) {
		.sm-hide { display: none; }
	}
	
	@media screen and (max-width:$xs-break) {
		.xs-hide { display: none; }
	}
	
	@media screen and (max-width: $xxs-break) {
		.xxs-hide { display: none; }
	}

}
.xxs-show{display:none !important;}
.xxs-flex{display:none !important;}
.xs-show{display:none !important;}
.xs-flex{display:none !important;}
.sm-show{display:none !important;}
.sm-flex{display:none !important;}
.md-show{display:none !important;}
.md-flex{display:none !important;}

@include breakpoints.break-on(xxs, max) {
  .xxs-hide{display:none !important;}
  .xxs-show{display: block !important;}
  .xxs-flex{display: flex !important;}
}

@include breakpoints.break-on(xs, max) {
  .xs-hide{display:none !important;}
  .xs-show{display: block !important;}
  .xs-flex{display: flex !important;}
}
@include breakpoints.break-on(sm, max) {
  .sm-hide{display:none !important;}
  .sm-show{display: block !important;}
  .sm-flex{display: flex !important;}
}
@include breakpoints.break-on(md, max) {
  .md-hide{display: none !important;}
  .md-show{display: block !important;}
  .md-flex{display: flex !important;}
}

.inline{
	max-width: 1180px;
	margin:0 auto;
	
	@include breakpoints.break-on(sm, min) {
		padding: 0 1.5rem;
	}
	@include breakpoints.break-on(lg, min) {max-width: 1180px;}
  @include breakpoints.break-on(xl, min) {max-width: 1480px;}
  @include breakpoints.break-on(xxl, min) {max-width: 1940px;}
}

.pull-right {float:right;}
.inline-flex {display: inline-flex;justify-content: flex-end;}


.flex{display:flex;
	.flex--single {flex: 1;display: flex;
		@include breakpoints.break-on(sm, max) {display: block;flex: none;}
		img {line-height: inherit;width: 100%;}
		&.padded {padding-right: .25rem;
			&:last-child {padding-right: 0;}
		}
	}
	&.centered { align-items: center; }
	&.flex-wrap {flex-wrap: wrap; }
	&.flex-notrows {flex-direction: column; }
	&.flex-rows {flex-direction: row; }
	&.flex-grow {flex-grow: 1; }
	&.flex-center {align-items: center; }
	&.flex-justify-end {justify-content: flex-end; }
	&.flex-justify-center {justify-content: center; }
	&.input-row {padding: .5rem 0 .5rem 0;flex-direction: column;
		&.no-top {padding-top: 0;}
		&.no-bottom {padding-bottom: 0;}
		@include breakpoints.break-on(sm, min) {padding-right: 1rem;flex-direction: row;} 
	}
}

$sides: left, top, bottom, right;

@for $i from 1 to 4 {
	@each $side in $sides {
		.m-#{$side}-#{$i} {
			margin-#{$side}: #{$i}rem;
		}
	}
}

@for $i from 1 to 4 {
	@each $side in $sides {
		.p-#{$side}-#{$i} {
			padding-#{$side}: #{$i}rem !important;
		}
	}
}
