blockquote {
  position: relative;
  font-size: 1.125rem;
  font-style: italic;
  line-height: 1.33;
  color: var(--blockquote-color, var(--color-light-green));
  margin: 2rem 1rem 2rem 3.125rem;

  &:before {
    content: '';
    background-image: url('/assets/img/icons/format-quote-outlined.svg');
    background-repeat: no-repeat;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    left: -2.25rem;
  }
}

// Dark mode overrides
.dark-mode {
  & blockquote {
    --blockquote-color: var(--color-white);

    &::before {
      filter: invert(1);
    }
  }
}
