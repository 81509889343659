.tooltip{
	z-index: 9999;
	&-inner{
		max-width: 300px;
		background: rgba(97,97,97,9);
		border-radius: 0.25rem;
		padding:0.25rem 0.5rem;
		font-size: 0.75rem;
		color: var(--color-white);
		font-weight: normal;
	}
	&.bs-tooltip-right{
		margin-left: 0.875rem;
		&:after {
			right: 100%;
			top: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(136, 183, 213, 0);
			border-right-color: rgba(97,97,97,9);
			border-width: 6px;
			margin-top: -6px;
		}
	}
	&.bs-tooltip-left{
		margin-right: 0.875rem;
		&:after {
			left: 100%;
			top: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(34, 207, 213, 0);
			border-left-color: rgba(97,97,97,9);
			border-width: 6px;
			margin-top: -6px;
		}
	}
	&.bs-tooltip-top{
		margin-bottom: 0.875rem;
		&:after {
			top: 100%;
			left: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(0, 0, 0, 0);
			border-top-color: rgba(97,97,97,9);
			border-width: 6px;
			margin-left: -6px;
		}
	}
	&.bs-tooltip-bottom{
		margin-top: 0.875rem;
		&:after {
			bottom: 100%;
			left: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(0, 0, 0, 0);
			border-bottom-color: rgba(97,97,97,9);
			border-width: 6px;
			margin-left: -6px;
		}
	}
}
