@use 'layers' as layers;
@use "@angular/material/prebuilt-themes/indigo-pink.css" as mat;
@use 'colors' as colors;
@use 'fonts' as fonts;
@use 'typography' as typography;
@use 'grid' as grid;
@use 'overrides/index' as overrides;

$create-font-face: false;

html{font-family:Rubik,Arial,Helvetica,sans-serif;}
html,body {
	overflow-x: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0;
	padding: 0;
}

*{box-sizing: border-box;}
a{color: #2e3374;
	&:hover{text-decoration: none;}
}
.device-desktop{
	a{
		&:hover{text-decoration: none;}
	}
}

.corner-logo {
  z-index: 10;
  position: fixed;
  bottom: 0;
	right: 1.5rem;
	transition: transform 0.2s;
	transform: translateY(0);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
	-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
}

.corner-logo.hidden {
	transform: translateY(100%);
}

@keyframes ripple {
	0% {
		opacity: 0.3;
		transform: scale(0);
	}

	100% {
		opacity: 0;
		transform: scale(30);
	}
}


.cdk-live-announcer-element {
  position: fixed;
  bottom: 0;
  left: -100%;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

// Dark mode specific changes
.dark-mode {
	color-scheme: dark;
	& a {
		// Allow overrides, but default to regular dark color variable
		color: var(--generic-link-color, var(--color-dark-link));
	}
}
