$white: #ffffff;
$light-gray: #eeeeee;
$light-gray-alternate: #F5F5F8;
$gray: #c7c7c9;
$dark-gray: #757575;
$middle-gray: #9B9B9B;
$bluish-gray: #626A7F;
$darker-gray: #333;
$orange: #f8b243;
$dark-orange: #fd8208;
$light-orange: #ffe7c1;
$red: #d5401a;
$dark-red: rgba(208, 2, 27, 0.87);
$dark-red-alternative: #D62339;
$light-red: #fbece8;
$light-blue: #15adc2;
$bright-blue: #8ad6e0;
$aqua: #bee3e8;
$blue: #2e3374;
$blue-light: #454b92;
$light-purple: #bcbcd2;
$light-green: #0F7B8A;
$black: #333333;
$visited: #623258;
$youtube-red: #FF0000;
$youtube-black: #212121;

$success: #01844E;
$success-alternate: #008D53;
$success-light: #E6F5EF;
$info: #1565c0;
$info-light: #f1f7fe;
$error: #d0021b;
$error-light: #fdf3f4;
$warning: #f5a623;
$warning-light: #fff7eb;

$theme-default-lighter: #DFF1F3;
$theme-default: #BEE3E8;
$theme-default-darker: #15ADC2;
$theme-career-lighter: #D1F1E3;
$theme-career: #C1E9CA;
$theme-career-darker: #4A8958;
$theme-teaching: #DFDCFF;
$theme-teaching-dark: #C1BEE8;
$theme-teaching-darker: #8F67C5;
$theme-young: #CFDEFB;
$theme-young-darker: #6080BD;
$theme-learning-lighter: #F5FAE7;
$theme-learning: #E4F1C0;
$theme-learning-darker: #58730C;
$theme-youth-monitoring: #D1F1E3;
$theme-youth-monitoring-alt: #CFF0E1;
$theme-youth-monitoring-darker: #B5E7D2;

$footer: #383A45;

// Dark mode
$dark-content-bg: #313239;

.color-white {
  color: $white;
}

.color-light-gray {
  color: $light-gray;
}

.color-gray {
  color: $gray;
}

.color-dark-gray {
  color: $dark-gray;
}

.color-orange {
  color: $orange;
}

.color-dark-orange {
  color: $dark-orange;
}

.color-light-orange {
  color: $light-orange;
}

.color-red {
  color: $red;
}

.color-dark-red {
  color: $dark-red;
}

.color-light-blue {
  color: $light-blue;
}

.color-bright-blue {
  color: $bright-blue;
}

.color-aqua {
  color: $aqua;
}

.color-blue {
  color: $blue;
}

.color-blue-light {
  color: $blue-light;
}

.color-light-purple {
  color: $light-purple;
}

.color-light-green {
  color: $light-green;
}

.color-black {
  color: $black;
}

.color-visited {
  color: $visited;
}

.color-success {
  color: $success;
}

.color-success-light {
  color: $success-light;
}

.color-info {
  color: $info;
}

.color-info-light {
  color: $info-light;
}

.color-error {
  color: $error;
}

.color-error-light {
  color: $error-light;
}

.color-warning {
  color: $warning;
}

.color-warning-light {
  color: $warning-light;
}

// Vars
:root {
  --color-form-field: #{$blue};
  --color-datepicker-icon: #{$error};
  --color-form-field-border: #{$gray};
  --color-error: #{$error};
  --color-error-light: #{$error-light};
  --color-dark-gray: #{$dark-gray};
  --color-light-gray: #{$light-gray};
  --color-black: #{$black};
  --color-white: #{$white};
  --color-blue: #{$blue};
  --color-light-blue: #{$light-blue};
  --color-middle-gray: #{$middle-gray};
  --color-dynamic-light-gray: #{$light-gray};
  --color-gray: #{$gray};
  --color-footer: #{$footer};
  --color-success-alternate: #{$success-alternate}; 
  --color-light-gray-alternate: #{$light-gray-alternate};
  --color-aqua: #{$aqua};
  --color-bluish-gray: #{$bluish-gray};
  --color-light-green: #{$light-green};
  --color-dark-red: #{$dark-red};
  --color-dark-red-alternative: #{$dark-red-alternative};
  --color-light-gray-alternate: #{$light-gray-alternate};
  --color-info: #{$info};
  --color-info-light: #{$info-light};
  --color-success: #{$success};
  --color-success-alternate: #{$success-alternate};
  --color-red: #{$red};
  --color-theme-learning: #{$theme-learning};
  --color-theme-learning-lighter: #{$theme-learning-lighter};
  --color-generic-box-shadow: #8D8D8D40;
  --color-blue-light: #{$blue-light};
  --color-super-light-blue: #{$theme-default-lighter};
  --color-bright-blue: #{$bright-blue};
  --color-warning: #{$warning};
  --color-warning-light: #{$warning-light};
  --color-bright-blue: #{$bright-blue};
  --color-youth-monitoring-light-green: #C9EEDE;
  --color-youth-monitoring-dark-green: #B5E7D2;
  --color-youth-monitoring: #{$theme-youth-monitoring};
  --color-youth-monitoring-alt: #{$theme-youth-monitoring-alt};
  --color-dark-orange: #{$dark-orange};
  --color-middle-gray-alternate: #949494;
  --color-theme-default: #{$theme-default};
  --color-half-transparent-white: rgba(255, 255, 255, 0.5);
  --color-orange: #{$orange};
  --color-light-orange: #{$light-orange};
  --color-link-visited: #{$visited};
  --color-theme-teaching-dark: #{$theme-teaching-dark};
  --color-theme-career: #{$theme-career};
  --color-theme-default-darker: #{$theme-default-darker};
  --color-theme-career-darker: #{$theme-career-darker};
  --color-theme-teaching: #{$theme-teaching};
  --color-theme-teaching-darker: #{$theme-teaching-darker};
  --color-theme-young: #{$theme-young};
  --color-theme-young-darker: #{$theme-young-darker};
  --color-theme-learning-darker: #{$theme-learning-darker};
  --color-success-light: #{$success-light};
  --color-youtube-black: #{$youtube-black};
  --color-youtube-red: #{$youtube-red};
  --color-light-purple: #{$light-purple};
  --color-light-red: #{$light-red};

  &.dark-mode {
    // Dark mode
    --color-dark-bg: #242424;
    --color-dark-content-bg: #313239;
    --color-dark-accent: #3D3E4F;
    --color-dark-button-text: #1C1C26;
    --color-dark-link: #737DFF;
    --color-dark-button: #D7DAFF;
    --color-dark-button-hover: #737DFF;
    --color-dark-homepage-bg: #7772E4;
    --color-dark-sidemenu-black: #00000040;
    --color-dark-info: #62abff;
    --color-dark-error: #ff7b8a;
    --color-dark-success: #44ffb1;
    --color-dark-footer: #{$footer};
    --color-dark-light-bg: #41434D;
    --color-dynamic-light-gray: var(--color-dark-content-bg);
    // Mostly html from Drupal
    --generic-text-color: var(--color-white);
    --generic-link-color: var(--color-dark-link);
    // Gets rid of gray edges etc
    --generic-sidebar-block-bg: none;
    --generic-icon-color: var(--color-white);
    --generic-separator-color: var(--color-dark-button-text);
    // More specific content colors
    // Also more likely to be overridden
    --content-link-color: var(--color-white);
    --content-title-color: var(--color-white);
    // Used in multiple separate pages
    --list-filter-bg-color: var(--color-dark-content-bg);
    --table-cell-text-color: var(--color-white);
    --table-cell-color: var(--color-dark-button-text);
    --table-separator-bg-color: var(--color-dark-button-text);
    --color-dark-mode-transparent-box-shadow: transparent;
    --color-dark-mode-blue: var(--color-blue);
    --color-dark-mode-blue-light: #{$blue-light};
    --color-dark-mode-white: var(--color-white);
    --color-dark-mode-black: var(--color-black);
    --color-dark-mode-bluish-gray: var(--color-bluish-gray);
    --color-dark-mode-light-gray: var(--color-light-gray);
    --color-dark-mode-gray: var(--color-gray);
    --color-dark-mode-half-transparent-black: rgba(51, 51, 51, 0.5);

    & .mat-mdc-tooltip {
      --mdc-plain-tooltip-container-color: var(--color-dark-accent);
    }
  }
}
